/**
 * CoreState Helper
 * Global state function for all cases
 * 
 * @version 1.0
 */

import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const pathname = window.location.pathname.replace(/\//g, '_');

const Store = create(
    persist(
        (set, get) => ({
            state: {},
            getItem: (field, parent = false) => {
                const state = get().state
                if (!parent) return state[pathname][field]
                return state[field]
            },
            setItem: (field, value, parent = false) => {
                const state = get().state
                if (!parent) {
                    if (!state[pathname]) {
                        state[pathname] = {[field]: value}
                    } else {
                        state[pathname][field] = value
                    }
                } else {
                    state[field] = value
                }

                set({state: state})
            },
            removeItem: (field, parent = false) => {
                const state = get().state
                if (!parent) {
                    delete state[pathname][field]
                } else {
                    delete state[field]
                }
                set({state: state})
            }
        }),
        {
            name: 'APP_CORE_STORAGE_V1',
            storage: createJSONStorage(() => sessionStorage)
        }
    )
);

export default Store;