import React from 'react'

function Input({iconRight, iconLeft, labelBottom, ...props}) {
  return (
    <React.Fragment>
      <label className="form-control w-full max-w-xs">
        <div className="input w-full input-bordered flex items-center gap-2">
          {iconLeft}
          <input className="grow" {...props} />
          {iconRight}
        </div>
        {
          labelBottom && 
          (
            <div className="label">
              <span className="label-text-alt">Bottom Left label</span>
            </div>
          )
        }
      </label>
    </React.Fragment>
  )
}

export default Input