import { MyInput } from '@/components/common/Form'
import Content from '@/components/specific/Content'
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle'
import { useGetDetailPelanggan } from '@/utils/hooks/useFetchWrapper'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import numeral from 'numeral';

import global from '../../../utils/helpers/global';
import { _getDetailPelanggan } from '../../../utils/apis/InfoPelanggan';

function Tagihan() {
    const { search } = useLocation();
    const qparams = new URLSearchParams(search);

    var history = qparams.get('history') ?? null;
    if (history === 'null') history = null;

    var last_page = qparams.get('last_page') ?? null;
    if (last_page === 'null') last_page = null;

    const contentHeader = {
        title: 'Info Tagihan'
    }

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [pelanggan, setPelanggan] = useState(null)
    const [summary, setSummary] = useState({
        amount: 0,
        month: 0
    });
    const [dataTagihan, setDataTagihan] = useState([])

    const [firstInit, setFirstInit] = useState(true);
    const [page, setPage] = useState(last_page ?? 1);
    const [totalPage, setTotalPage] = useState(1);

    const [payload, setPayload] = useState({
        kode_pelanggan: history,
        page: page,
        limit: 10,
        order_by_field: 'period',
        order_by_type: 'desc'
    });

    const getData = async (_page = null) => {
        try {
            setIsLoading(true);
            setPelanggan(null);

            let _payload = payload;
            if (_page) _payload.page = _page;
            const response = await _getDetailPelanggan(_payload);
            const data = response?.data?.data ?? null;

            if (data?.pelanggan) {
                // appending summary
                const _summary = data.list_tagihan?.summary ?? null;
                if (_summary) {
                    setSummary({
                        amount: _summary?.amount ?? 0,
                        month: _summary?.month ?? 0
                    });
                }

                // preparing data tagihan
                const list_tagihan = data.list_tagihan?.data ?? [];
                let data_tagihan = [];

                // private variable
                const _fieldsInDetail = ['id', 'periode_tahun', 'periode_bulan', 'status', 'total_tagihan'];

                // perform to filling up the data
                for (let i = 0; i < list_tagihan.length; i++) {
                    let each_data = {
                        id: null,
                        year: null,
                        month: null,
                        status: null,
                        total_tagihan: 0
                    }

                    // looking for detail
                    for (let j = 0; j < list_tagihan[i].length; j++) {
                        if (_fieldsInDetail.includes(list_tagihan[i][j]?.key?.toLowerCase())) {
                            let f = list_tagihan[i][j].key.toLowerCase();
                            if (f === 'periode_bulan') f = 'month';
                            if (f === 'periode_tahun') f = 'year';
                            each_data[f] = list_tagihan[i][j]?.value ?? null;
                        }
                    }

                    // prepare to joining
                    // grouping by year for each detail
                    if (each_data.id !== null && each_data.year !== null) {
                        each_data.month = global.convert_month(each_data.month);
                        const x = data_tagihan.findIndex(x => x?.year === each_data.year);
                        if (x >= 0) {
                            data_tagihan[x].details.push(each_data);
                        } else {
                            data_tagihan.push({
                                year: parseInt(each_data.year),
                                details: [each_data]
                            });
                        }
                    }
                }

                // updating state
                setPelanggan(data?.pelanggan ?? null);
                setFirstInit(false);
                setDataTagihan(data_tagihan);
                setTotalPage(data?.list_tagihan?.total_page ?? 1);
            } else {
                setIsError(true);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setIsError(true);
        }
    }

    useEffect(() => {
        setPage(last_page ?? 1);
        async function __init() {
            setIsLoading(true);
            setFirstInit(true);
            await getData(last_page ?? 1);
        }
        if (history) __init(); // first init if there is default data
    }, []);

    return (
        <Content>
            <h1 style={{
                textAlign: 'center',
                marginBottom: 10,
                marginTop: pelanggan ? 0 : 150,
                fontWeight: 300,
                fontSize: 40
            }}>
                Info Tagihan
            </h1>
            <div className="md:w-2/6 flex gap-2 justify-center" data-theme="mytheme" style={{
                margin: 'auto',
            }}>
                <MyInput
                    type="text"
                    placeholder="Masukan Nomor Sambung"
                    defaultValue={payload.kode_pelanggan}
                    onChange={(e) => setPayload({ ...payload, kode_pelanggan: e.target.value })}
                />
                <button
                    className="btn bg-primary text-white hover:bg-primary"
                    onClick={() => {
                        getData();
                        setFirstInit(true);
                    }}
                >
                    Cari
                </button>
            </div>

            {/* Data Pelanggan */}
            {
                (pelanggan) &&
                (
                    <div className="w-full">
                        <h3>Data Pelanggan</h3>

                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="w-full md:w-4/12">
                                <span className="font-medium text-base-content">Nomor Sambung</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.kode_pelanggan}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="w-full md:w-4/12">
                                <span className="font-medium text-base-content">Nama Pelanggan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.nama}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="w-full md:w-4/12">
                                <span className="font-medium text-base-content">Alamat</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.alamat}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="w-full md:w-4/12">
                                <span className="font-medium text-base-content">Total Tunggakan Pelanggan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">Rp. {numeral(summary.amount).format('0,0')}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="w-full md:w-4/12">
                                <span className="font-medium text-base-content">Total Jumlah Bulan Rekening Tunggakan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{numeral(summary.month).format('0,0')} Bulan</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="w-full md:w-4/12">
                                <span className="font-medium text-base-content">Golongan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.golongan}</span>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Periode Tahun */}
            {
                (pelanggan) &&
                (
                    <div className="w-full">
                        <h3>Periode Tahun</h3>
                        {
                            dataTagihan.map((data, idx) => (
                                <div key={idx} className="collapse collapse-arrow bg-base-200 mt-2">
                                    <input type="radio" name="my-accordion-2" defaultChecked={idx === 0} />
                                    <div className="collapse-title text-xl font-medium h-[20px]">{data?.year}</div>
                                    <div className="collapse-content mt-2 bg-base-100">
                                        <div className="overflow-x-auto">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Periode</th>
                                                        <th>Total</th>
                                                        <th>Status</th>
                                                        <th>Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.details?.map((d, idx) => (
                                                            <tr key={idx}>
                                                                <td>{`${d?.month} ${data?.year}`}</td>
                                                                <td>Rp. {numeral(d?.total_tagihan).format('0,0')}</td>
                                                                <td>
                                                                    <div className="badge badge-success text-white">
                                                                        {d?.status ?? 'BELUM LUNAS'}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Link
                                                                        to={`/info-tagihan/${d?.id}?history=${pelanggan?.kode_pelanggan}&last_page=${page}`}
                                                                        className="btn btn-info text-white btn-xs"
                                                                    >
                                                                        Tagihan
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }

            {/* show loading */}
            {
                (isLoading) &&
                (
                    <div className="w-full flex flex-col gap-5">
                        <div className="skeleton h-4 w-full"></div>
                        <div className="skeleton h-4 w-10/12"></div>
                        <div className="skeleton h-4 w-9/12"></div>
                    </div>
                )
            }

            {/* show paging */}
            {
                (!firstInit && !isError) &&
                (
                    <nav aria-label="Page navigation" className="flex justify-center my-4">
                        <ul className="inline-flex space-x-2" style={{ listStyle: 'none' }}>
                            <li>
                                <button className="px-4 py-2 border rounded text-gray-600 bg-white hover:bg-gray-200" aria-label="Previous" onClick={() => {
                                    let _page = page;
                                    if (_page > 1) {
                                        _page -= 1;
                                        setPage(_page);
                                        getData(_page);
                                    }
                                }}>
                                    &laquo;
                                </button>
                            </li>
                            {Array.from({ length: totalPage }, (_, index) => index + 1).map((n) => (
                                <li key={n}>
                                    <button
                                        className={`px-4 py-2 border rounded ${parseInt(page) === parseInt(n) ? 'bg-blue-500 text-white' : 'text-blue-500 bg-white'} hover:bg-blue-100`}
                                        onClick={() => {
                                            setPage(n);
                                            getData(n);
                                        }}
                                    >{n}</button>
                                </li>
                            ))}
                            <li>
                                <button className="px-4 py-2 border rounded text-gray-600 bg-white hover:bg-gray-200" aria-label="Next" onClick={() => {
                                    let _page = page;
                                    if (_page < totalPage) {
                                        _page += 1;
                                        setPage(_page);
                                        getData(_page);
                                    }
                                }}>
                                    &raquo;
                                </button>
                            </li>
                        </ul>
                    </nav>
                )
            }

            {
                (isError && !isLoading) &&
                (
                    <div className="w-full h-20 rounded-md flex flex-col items-center justify-center gap-5">
                        <span className="text-red-500">
                            {/* {error?.response?.data?.message ?? error?.data?.message ?? 'Error'} */}
                            <p style={{
                                fontStyle: 'italic',
                                fontWeight: 200,
                                fontSize: 18
                            }}>
                                -- Data pelanggan tidak ditemukan --
                            </p>
                        </span>
                    </div>
                )
            }
        </Content>
    )
}

export default Tagihan