import Content from '@/components/specific/Content'
import React from 'react'

function Home() {
  return (
    <Content showContentHeader={false}>
      Welcome Home
    </Content>
  )
}

export default Home