import { Store } from "@/store";
import axios from "axios";

const store = Store.getState()

const mainHost = process.env.REACT_APP_API_HOST_NAME
const MAIN_API = axios.create({baseURL: mainHost})

MAIN_API.interceptors.request.use(
    config => {
        const auth = store.getItem('auth', true)
        const isAuthenticated = auth?.isAuthenticated ?? false
        const token = auth?.token ?? false
        if (token && isAuthenticated) {
            config.headers['authorization'] = `Bearer ${token}`
        } else {
            console.log('NO TOKEN => ', token);
        }

        config.headers['X-Nurjon-Header'] = 'paquito'
        return config
    },
    error => Promise.reject(error)
)

MAIN_API.interceptors.response.use(
    (response) =>  {
        let callback = {
            success: true,
            statusCode: response.status,
            message: response.statusText,
            data: response.data
        }
        return callback
    },
    (error) => {
        const expiredToken = (error.response?.data?.detail ?? '')
        if (expiredToken === 'Invalid token or expired token.') {
            
            setTimeout(() => {
                window.location.href = '/auth/login';
            }, 2000)
            setTimeout(() => {
                alert('Token Expired')
            }, 1000)
        }
        return Promise.reject(error)
    }
)
export async function get(endpoint, options) {
    return await MAIN_API.get(endpoint, options)
}
export async function post(endpoint, payload) {
    return await MAIN_API.post(endpoint, payload)
}
export async function put(endpoint, payload) {
    return await MAIN_API.put(endpoint, payload)
}
export async function del(endpoint) {
    return await MAIN_API.delete(endpoint)
}

export {axios}