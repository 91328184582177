import React from 'react'

function ContentHeader({title, breadcrumbs}) {
  return (
    <div className="mt-3 rounded-sm prose">
        {
            title && 
            (
                <span className='text-neutral text-3xl md:text-4xl font-bold'>{title}</span>
            )
        }
        {
            (breadcrumbs) &&
            (
                <div className="breadcrumbs text-sm mt-2 p-0">
                    <ul className='m-0 p-0'>
                        {
                            breadcrumbs.map((bc, idx) => (
                                <li key={idx} className='m-0 p-0'>
                                    <a href={bc.link}>{bc.name}</a>
                                </li>
                            ))
                        }
                        <li className='m-0 p-0'>
                            {title}
                        </li>
                    </ul>
                </div>
            )
        }
    </div>
  )
}

export default ContentHeader