import { get } from "../http/instance";

export const getDetailPelanggan = ({ queryKey }) => {
    const [_, payload] = queryKey

    const rest = get('pelanggan/' + payload.id, {
        params: {
            page: payload.page,
            limit: payload.limit,
            status: payload.status,
            order_by_field: payload.order_by_field,
            order_by_type: payload.order_by_type,
        }
    })
    return rest
}

export const _getDetailPelanggan = (payload) => {
    const rest = get('pelanggan/' + payload.kode_pelanggan, {
        params: {
            page: payload.page,
            limit: payload.limit,
            status: payload.status,
            order_by_field: payload.order_by_field,
            order_by_type: payload.order_by_type,
        }
    });
    return rest;
}

export const getDetailTagihan = async (id) => {
    const rest = await get('pelanggan/detail/' + id);
    return rest;
}