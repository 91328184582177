const convert_month = (month) => {
    if (month === null || month === undefined) return month;
    month = parseInt(month);
    ['January', "February", "Maret", "April", "Mei", "Juni", "July", "Agustus", "September", "Oktober", "November", "Desember"].map((v, i) => {
        if ((i + 1) === month) {
            month = v;
            return;
        }
    });
    return month;
}

export default {
    convert_month
}