import { MyContainer, MyContentHeader } from '@/components/common'
import { Navbar } from '@/components/specific'
import React from 'react'
import { Outlet } from 'react-router-dom'

function Base() {
  return (
    <div className='flex flex-col relative min-h-screen drawer'>
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <Navbar />
      <MyContainer className="flex-grow gap-8 drawer-content">
        <Outlet />
      </MyContainer>
      <div className="drawer-side">
        <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul 
          className="menu p-4 w-80 bg-base-100 text-base-content min-h-full"
          style={
            {
              minHeight: 'calc(100vh - 64px)',
              marginTop: 64
            }
          }
        >
        <li>
            <a href="/#">Home</a>
          </li>

          {/* Info Tagihan with Nested Submenus */}
          <li tabIndex={0} className="collapse collapse-arrow">
            <input type="checkbox" className='py-0'/>
            <div className="collapse-title flex items-center py-0 justify-between">
              <span>Info Pelanggan</span>
            </div>
            <ul className="collapse-content p-0 m-0 bg-base-200">
              <li>
                <a href="/info-tagihan">Info Tagihan</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <footer className="footer mt-5 footer-center bg-base-200 text-base-content p-4">
        <aside>
          <p>Copyright © {new Date().getFullYear()} - Tim IT PDAM Kabupaten Donggala</p>
        </aside>
      </footer>
    </div>
  )
}

export default Base