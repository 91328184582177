import { Store } from '@/store'
import React from 'react'
import { Navigate } from 'react-router-dom'

function Midleware({render, isProtected}) {
  const store = Store((state) => state)
  const auth = store.getItem('auth', true)
  const isAuthenticated = auth?.isAuthenticated ?? false
  const token = auth?.token ?? false
  const currentFullURI = window.location.href;

  if (isProtected === false) return render
  if (isAuthenticated && token) return render
  return <Navigate to="/auth/login" state={{from: currentFullURI}} replace />
  
}

export default Midleware