import { PageTagihan, PageTagihanDetail } from "@/pages/InfoPelanggan";

export const TagihanRouter = [
    {
        path: 'info-tagihan',
        element: <PageTagihan />
    },
    {
        path: 'info-tagihan/:id?',
        element: <PageTagihanDetail />
    },
]