import React, { useEffect, useState } from 'react'
import { MyContainer } from '../common'
import { Link } from 'react-router-dom';

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={`navbar z-[100] bg-[#095393] sticky top-0 ${isScrolled ? '' : ''}`}>
      <MyContainer>
        <div className="flex w-full flex-1 justify-between items-center">
          <Link to="https://pdamuwelino.co.id/" className="flex flex-1 flex-col items-start">
            <img src="/pdam-uwelino-header.png" className="w-[200px] md:w-[300px]" alt="logo" />
          </Link>
          <div className="hidden md:flex">
            <div className="dropdown">
              <div tabIndex={0} role="button" className="btn btn-ghost text-white">
                Info Pelanggan
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                <li><Link to="info-tagihan">Info Tagihan</Link></li>
              </ul>
            </div>
          </div>
          <div className="flex flex-1 justify-end md:hidden">
            <label htmlFor="my-drawer" className="btn btn-ghost drawer-button text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </label>
          </div>
        </div>
      </MyContainer>
    </div>
  )
}

export default Navbar