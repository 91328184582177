import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { MyContentHeader } from '../common'
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle'

function Content({children, contentHeader, showContentHeader = true}) {
  useDocumentTitle(contentHeader?.title ?? 'PDAM Kabupaten Donggala')

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='flex flex-col gap-8 p-2 md:p-0'
    >
        {
          showContentHeader &&
          (
            <MyContentHeader 
                title={contentHeader?.title ?? null}
                breadcrumbs={contentHeader?.breadcrumbs ?? []}
            />
          )
        }
        <div className="flex flex-col gap-5 w-100 prose max-w-none rounded-md p-2 md:p-5">
            {children}
        </div>
    </motion.div>
  )
}

export default Content