import { Home } from "@/pages/home";

const { createBrowserRouter, Navigate } = require("react-router-dom");
const { Midleware, Base, Auth, NotFound } = require("@/layout");
const { AuthRouter, TagihanRouter } = require("./public");

export const routerApp = createBrowserRouter([
    {
        path: '/',
        element: <Midleware isProtected={false} render={<Base />}/>,
        children: [
            {
                index: true,
                element: <Navigate to="/info-tagihan" />
            },
            ...TagihanRouter
        ]
    },
    {
        path: 'auth',
        element: <Midleware isProtected={false} render={<Auth />}/>,
        children: [
            // Public Routes
            ...AuthRouter,
        ]
    },
    {
        path: "*",
        element: <NotFound />,
    },
])