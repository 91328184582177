import { Login, Register } from "@/pages/auth";
import { Navigate } from "react-router-dom";

export const AuthRouter = [
    {
        index: true,
        element: <Navigate to="login" replace/>
    },
    {
        path: 'login',
        element: <Login />
    },
    {
        path: 'register',
        element: <Register />
    }
]