import React from 'react'

function Container({children, className = '', ...props}) {
  return (
    <div 
      className={`customContainer w-full md:min-w-[100] md:max-w-[100] md:mx-auto flex flex-col ${className}`} {...props} 
    >
        {children}
    </div>
  )
}

export default Container